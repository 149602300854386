import { render, staticRenderFns } from "./ScenarioDetail.vue?vue&type=template&id=5db870c1&"
import script from "./ScenarioDetail.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ScenarioDetail.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports