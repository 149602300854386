var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.$props.isOpen),callback:function ($$v) {_vm.$set(_vm.$props, "isOpen", $$v)},expression:"$props.isOpen"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-4"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-select',{staticClass:"mr-2",attrs:{"items":_vm.$props.allChapters.map((chapter, index) => {
              return {
                text: `チャプター${index + 1}${chapter.title ? `: ${chapter.title}` : ''}`,
                value: index,
                disabled: index === _vm.$props.chapterIndex,
              };
            }),"placeholder":"チャプターを選択","item-title":"text","item-value":"value","hide-details":"","variant":"outlined"},model:{value:(_setup.selectedChapter),callback:function ($$v) {_setup.selectedChapter=$$v},expression:"selectedChapter"}}),_vm._v(" "),_c('span',[_vm._v("に移動する")])],1)]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"grey","variant":"text"},on:{"click":_setup.cancel}},[_vm._v("キャンセル")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","variant":"flat"},on:{"click":_setup.confirm}},[_vm._v("決定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }