import { render, staticRenderFns } from "./BlockEditor.vue?vue&type=template&id=7b9a38de&scoped=true&"
import script from "./BlockEditor.vue?vue&type=script&setup=true&lang=ts&"
export * from "./BlockEditor.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./BlockEditor.vue?vue&type=style&index=0&id=7b9a38de&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b9a38de",
  null
  
)

export default component.exports