import { render, staticRenderFns } from "./DescriptionPreviewModal.vue?vue&type=template&id=b81b0df0&scoped=true&"
import script from "./DescriptionPreviewModal.vue?vue&type=script&setup=true&lang=ts&"
export * from "./DescriptionPreviewModal.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./DescriptionPreviewModal.vue?vue&type=style&index=0&id=b81b0df0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b81b0df0",
  null
  
)

export default component.exports