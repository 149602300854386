import { render, staticRenderFns } from "./WordDescriptionCreate.vue?vue&type=template&id=7c170fc2&"
import script from "./WordDescriptionCreate.vue?vue&type=script&setup=true&lang=ts&"
export * from "./WordDescriptionCreate.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports