
import { ValidationProvider } from "vee-validate";

export default {
  name: "TextInputWithValidate",

  components: {
    ValidationProvider,
  },

  props: {
    label: {
      default: "",
      type: String,
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    value: {
      required: true,
    },
    rules: {
      type: undefined, // オブジェクトで渡したいパターンがあったので型エラーを防ぐためにundefinedを指定
      required: true,
    },
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      show: false,
    };
  },

  computed: {
    newValue: {
      get() {
        return this.value;
      },
      set(val) {
        if (this.value !== val) {
          this.$emit("input", val);
        }
      },
    },
    innerName() {
      return this.name ? this.name : this.label;
    },
    computedType() {
      if (this.$attrs.type !== "password") {
        return this.$attrs.type;
      }
      return this.show ? "text" : "password";
    },
  },
};
